import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Link, TextAccent, Article, ArrowExternal } from '../../components';
import { TestimonialList, TestimonialItem, TestimonialText, TestimonialAuthor } from './About.style';

/**
 * About: Testimonials composition
 */
const Testimonails = () => {
  const data = useStaticQuery(
    graphql`
      query {
        marko: file(relativePath: { eq: "marko-nikolic-an-face.png" }) {
          childImageSharp {
            fixed(width: 75) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `
  );

  return (
    <Article>
      <TestimonialList>
        <TestimonialItem>
          <TestimonialText withSpacing>
            “Danijel is doing such a great job in making products delightful and easy to use. For several years he has
            helped us deliver end-to-end solutions on a high level of quality. He is the person who I know I can go to
            for any development, or design related inquiries.”
          </TestimonialText>
          <TestimonialAuthor>
            <Img
              fixed={data.marko.childImageSharp.fixed}
              loading="eager"
              objectFit="cover"
              alt="Image of Marko Nikolić, senior partner at Iten Engineering"
            />
            <div>
              <TextAccent weight={700}>Marko Nikolić</TextAccent>
              <TextAccent colorKind="darkTransparent" element="span">
                Senior Partner at Iten Engineering
              </TextAccent>
            </div>
          </TestimonialAuthor>
        </TestimonialItem>
      </TestimonialList>
      <TextAccent>
        More testimonials can be found on my{' '}
        <Link to="https://www.linkedin.com/in/danijelgrabez">
          LinkedIn profile
          <ArrowExternal />
        </Link>
        .
      </TextAccent>
    </Article>
  );
};

export default Testimonails;
